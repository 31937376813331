import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ReactSVG } from 'react-svg';

import illness from '../assets/illness.svg';
import injury from '../assets/injury.svg';
import time from '../assets/time.svg';
import weather from '../assets/weather.svg';

const GRID_ITEM_STYLES = {
  border: '1px solid #ddd',
  height: 300,
  borderRadius: 3,
  p: { sx: 1, md: 2 },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const GRID_ITEM_CONTENT = {
  illness: {
    sx: 'How do I get back to where I was?',
    md: 'Sometimes we have to take a break, but how do we get back on track?',
  },
  injury: {
    sx: 'What recovery exercises should I do?',
    md: "Traditional plans don't adapt to optimise your recovery.",
  },
  time: {
    sx: 'I need a plan that fits my busy life',
    md: "Sometime's we're too busy, we need a plan that fits around life.",
  },
  weather: {
    sx: "I can't train outside, what can i do inside?",
    md: 'Our environment can demotivate us, how do we work around this?',
  },
};
const ProblemStatement = () => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [smallScreen, setSmallScreen] = React.useState(screenWidth < 600);

  React.useEffect(() => {
    if (screenWidth < 600) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [screenWidth]);

  return (
    <Box>
      <Typography variant="h1" sx={{ color: '#272620', textAlign: 'center', fontSize: { xs: 30, md: 40 } }}>
        Most fitness plans are abandoned.
      </Typography>
      <Typography
        sx={{
          color: '#272620',
          textAlign: 'center',
          mt: 3,
          fontSize: {
            md: 20,
            xs: 15,
          },
          fontWeight: 350,
        }}
      >
        The failure of fitness plans to adapt to every day life is the reason people do not stay consistent.
      </Typography>
      <Grid
        container
        columnGap={{ xs: 0.2, md: 2 }}
        rowGap={{ xs: 5, md: 0 }}
        justifyContent={'space-evenly'}
        sx={{ mt: { xs: 5, md: 10 } }}
      >
        <Grid item xs={5.3} md={2} sx={{ ...GRID_ITEM_STYLES, height: { xs: 200, md: 300 }, p: { xs: 1, md: 1 } }}>
          <ReactSVG src={illness} />
          <Typography sx={{ color: '#272620', fontSize: { xs: 15, md: 20 }, m: { xs: 1, md: 2 } }}>Sickness</Typography>
          <Typography sx={{ color: '#272620', fontWeight: 350, textAlign: 'center', fontSize: { xs: 12, md: 12 } }}>
            {smallScreen ? GRID_ITEM_CONTENT.illness.sx : GRID_ITEM_CONTENT.illness.md}
          </Typography>
        </Grid>
        <Grid item xs={5.3} md={2} sx={{ ...GRID_ITEM_STYLES, height: { xs: 200, md: 300 }, p: { xs: 1, md: 1 } }}>
          <ReactSVG src={injury} />
          <Typography sx={{ color: '#272620', fontSize: { xs: 15, md: 20 }, m: { xs: 1, md: 2 } }}>Injury</Typography>
          <Typography sx={{ color: '#272620', fontWeight: 350, textAlign: 'center', fontSize: { xs: 12, md: 12 } }}>
            {smallScreen ? GRID_ITEM_CONTENT.injury.sx : GRID_ITEM_CONTENT.injury.md}
          </Typography>
        </Grid>
        <Grid item xs={5.3} md={2} sx={{ ...GRID_ITEM_STYLES, height: { xs: 200, md: 300 }, p: { xs: 1, md: 1 } }}>
          <ReactSVG src={time} />
          <Typography sx={{ color: '#272620', fontSize: { xs: 15, md: 20 }, m: { xs: 1, md: 2 } }}>Time</Typography>
          <Typography sx={{ color: '#272620', fontWeight: 350, textAlign: 'center', fontSize: { xs: 12, md: 12 } }}>
            {smallScreen ? GRID_ITEM_CONTENT.time.sx : GRID_ITEM_CONTENT.time.md}
          </Typography>
        </Grid>
        <Grid item xs={5.3} md={2} sx={{ ...GRID_ITEM_STYLES, height: { xs: 200, md: 300 }, p: { xs: 1, md: 1 } }}>
          <ReactSVG src={weather} />
          <Typography sx={{ color: '#272620', fontSize: { xs: 15, md: 20 }, m: { xs: 1, md: 2 } }}>Weather</Typography>
          <Typography sx={{ color: '#272620', fontWeight: 350, textAlign: 'center', fontSize: { xs: 12, md: 12 } }}>
            {smallScreen ? GRID_ITEM_CONTENT.weather.sx : GRID_ITEM_CONTENT.weather.md}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProblemStatement;
