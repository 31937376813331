import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Container from '@mui/material/Container';
import AppBar from '../components/AppBar';
import Hero from '../components/Hero';
import ProblemStatement from '../components/ProblemStatement';
import SolutionStatement from '../components/SolutionStatement';
import HowWorks from '../components/HowWorks';
import Pricing from '../components/Pricing';
import Reviews from '../components/Reviews';
import FAQs from '../components/FAQs';
import Contact from '../components/Contact';
import Legal from '../components/Legal';

const base_url =
  process.env.NODE_ENV === 'production' ? 'https://api.ambassador.heysabio.com' : 'http://localhost:7074';

const MAX_WIDTH = 1440;
const CONTAINER_HEIGHT = 700;

// Default container styles
const styles = { height: CONTAINER_HEIGHT, borderRadius: 5, padding: 5 };

const Home = () => {
  const queryParams = new URLSearchParams(window.location.search);

  React.useEffect(() => {
    const run = async () => {
      const code = queryParams.get('code');

      if (code) {
        Cookies.set('affiliate', code);

        await axios.post(`${base_url}/users/attribute`, {
          code,
          step: 'page_view',
        });
      }
    };

    run();
  }, []);

  return (
    <Container maxWidth={MAX_WIDTH} disableGutters>
      <AppBar />
      <Container maxWidth={MAX_WIDTH} sx={{ bgcolor: '#EE6E12', mt: 12, ...styles }}>
        <Hero />
      </Container>
      <Container maxWidth={MAX_WIDTH} sx={{ bgcolor: '#fff', mt: 4, ...styles, height: { xs: 760, md: 610 } }}>
        <ProblemStatement />
      </Container>
      <Container
        maxWidth={MAX_WIDTH}
        sx={{ bgcolor: '#EE6E1210', ...styles, width: '95%', height: { xs: 720, md: 690 } }}
      >
        <SolutionStatement />
      </Container>
      <Container maxWidth={MAX_WIDTH} sx={{ bgcolor: '#fff', mt: 4, ...styles, height: { xs: 1150, md: 730 } }}>
        <HowWorks />
      </Container>
      <Container
        maxWidth={MAX_WIDTH}
        sx={{ bgcolor: '#EE6E1210', ...styles, width: '95%', height: { xs: 510, md: 600 }, pt: { xs: 5, md: 0 } }}
      >
        <Pricing />
      </Container>
      <Container maxWidth={MAX_WIDTH} sx={{ bgcolor: '#fff', mt: 8, ...styles, height: { xs: 1250, md: 610 } }}>
        <Reviews />
      </Container>
      <Container
        maxWidth={MAX_WIDTH}
        sx={{ bgcolor: '#fff', mt: { xs: 4, md: 6 }, ...styles, height: { xs: 870, md: 700 } }}
      >
        <FAQs />
      </Container>
      <Container
        maxWidth={MAX_WIDTH}
        sx={{ bgcolor: '#fff', ...styles, width: '95%', height: { xs: 480, md: 680 }, pt: { xs: 5, md: 10 } }}
      >
        <Contact />
      </Container>
      <Container
        maxWidth={MAX_WIDTH}
        sx={{ bgcolor: '#EE6E1210', ...styles, width: '95%', height: { xs: 260, md: 165 } }}
      >
        <Legal />
      </Container>
      <Container sx={{ height: 30 }} />
    </Container>
  );
};

export default Home;
