import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ReactSVG } from 'react-svg';

import smallLogo from '../assets/smallHeyLogo.svg';

const Legal = () => {
  return (
    <Grid container sx={{ pl: 1.5 }} rowSpacing={2}>
      <Grid item xs={12} md={6}>
        <ReactSVG src={smallLogo} />
        <Typography sx={{ fontSize: 12, color: '#272620', mt: 1 }}>
          Sabio: Dynamic fitness plans that update in real time.
        </Typography>
        <Typography sx={{ fontSize: 12, color: '#808182', mt: 1 }}>Copyright © 2024 - All rights reserved</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography sx={{ color: '#949596', fontWeight: 450 }}>Links</Typography>
        <Typography component="a" href="/blog" sx={{ display: 'block', color: '#949596', mt: 1, fontSize: 12 }}>
          Blog
        </Typography>
        <Typography
          component="a"
          href="/privacy-policy"
          sx={{ display: 'block', color: '#949596', mt: 1, fontSize: 12 }}
        >
          Privacy Policy
        </Typography>
        <Typography component="a" href="/terms" sx={{ display: 'block', color: '#949596', mt: 1, fontSize: 12 }}>
          Terms & Conditions
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Legal;
