import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { ArrowForward } from '@mui/icons-material';

// TODO: Do weather one

const TAB_CONTAINER_STYLE = {
  backgroundColor: '#fff',
  margin: 'auto',
  marginTop: 5,
  borderRadius: 3,
  p: 0.5,
};

const TickBox = ({ label, index }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, height: 35 }}>
      <Box
        sx={{
          bgcolor: '#49548A',
          width: 35,
          height: 35,
          borderRadius: 17.5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ color: '#fff' }}>{index}</Typography>
      </Box>
      <Typography sx={{ color: '#272620', mb: '1px', ml: 1.5, fontWeight: 350, flex: 1 }}>{label}</Typography>
    </Box>
  );
};

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            width: { xs: '100%', md: '80%' },
            margin: 'auto',
            height: 300,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

const SolutionStatement = () => {
  const [value, setValue] = React.useState(0);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [smallScreen, setSmallScreen] = React.useState(screenWidth < 600);

  React.useEffect(() => {
    if (screenWidth < 600) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [screenWidth]);

  const handleChange = (e, v) => {
    setValue(v);
  };

  const handleAppStore = () => {
    window.location.href = 'https://apps.apple.com/app/apple-store/id6474304071?pt=126798329&ct=Website&mt=8';
  };

  return (
    <Box>
      <Typography variant="h1" sx={{ color: '#272620', textAlign: 'center', fontSize: { xs: 30, md: 40 }, mt: 2 }}>
        Maintain your fitness goals despite disruptions.
      </Typography>
      <Typography
        sx={{
          color: '#272620',
          textAlign: 'center',
          mt: 3,
          fontSize: {
            md: 20,
            xs: 15,
          },
          fontWeight: 350,
        }}
      >
        Sabio adapts your fitness plan to overcome the twists and turns of life.
      </Typography>
      <Tabs
        sx={{
          ...TAB_CONTAINER_STYLE,
          '.Mui-selected': { backgroundColor: '#EE6E1220', borderRadius: 3, color: 'white' },
          '.MuiTabs-indicator': { display: 'none' },
          width: { sx: '100%', md: '80%' },
          mb: 5,
          mt: { xs: 5, md: 10 },
        }}
        variant="fullWidth"
        value={value}
        onChange={handleChange}
      >
        <Tab label="Sickness" />
        <Tab label="Injury" />
        <Tab label="Time" />
        <Tab label="Weather" />
      </Tabs>
      <Box sx={{ mt: 4 }}>
        <CustomTabPanel value={value} index={0}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TickBox label={'Describe the illness to Sabio.'} index={1} />
              <TickBox label={'Sabio schedules your rest days.'} index={2} />
              <TickBox label={"Let Sabio know when you've recovered."} index={3} />
              <TickBox label={'Sabio replans to get you back to where you were.'} index={4} />
              <Button
                variant="outlined"
                endIcon={<ArrowForward />}
                onClick={handleAppStore}
                sx={{ mt: 1, color: 'primary.main' }}
              >
                View on the AppStore
              </Button>
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TickBox label={'Describe the injury to Sabio.'} index={1} />
              <TickBox label={'Sabio schedules rest & recovery exercises.'} index={2} />
              <TickBox label={"Let Sabio know when you've recovered."} index={3} />
              <TickBox label={'Sabio optimises to get you back on track.'} index={4} />
              <Button
                variant="outlined"
                endIcon={<ArrowForward />}
                onClick={handleAppStore}
                sx={{ mt: 1, color: 'primary.main' }}
              >
                View on the AppStore
              </Button>
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TickBox label={'When onboarding, Sabio asks for your availability.'} index={1} />
              <TickBox label={'Sabio plans workouts around your time restrictions.'} index={2} />
              <TickBox label={'If things change, let Sabio know.'} index={3} />
              <TickBox label={'Sabio will consider the new data and replan.'} index={4} />
              <Button
                variant="outlined"
                endIcon={<ArrowForward />}
                onClick={handleAppStore}
                sx={{ mt: 1, color: 'primary.main' }}
              >
                View on the AppStore
              </Button>
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TickBox label={"Tell Sabio you'd like to train indoors."} index={1} />
              <TickBox label={'Sabio will ask about equipment available to you.'} index={2} />
              <TickBox label={'Based on the conversation Sabio will set you a substitute indoor activity.'} index={3} />
              <Button
                variant="outlined"
                endIcon={<ArrowForward />}
                onClick={handleAppStore}
                sx={{ mt: 1, color: 'primary.main' }}
              >
                View on the AppStore
              </Button>
            </Grid>
          </Grid>
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default SolutionStatement;
