import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import screens from '../assets/3phones.png';

// TODO: Figure out the subheading

const HowWorkBox = ({ index, title, label }) => {
  return (
    <Box sx={{ mb: 6, textAlign: { xs: 'center', md: 'left' } }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: { xs: 'center', md: 'flex-start' } }}>
        <Box
          sx={{
            bgcolor: '#49548A',
            width: 25,
            height: 25,
            borderRadius: 12.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mr: 1,
          }}
        >
          <Typography sx={{ color: '#fff' }}>{index}</Typography>
        </Box>
        <Typography sx={{ fontWeight: 450 }}>{title}</Typography>
      </Box>
      <Typography sx={{ fontWeight: 350 }}>{label}</Typography>
    </Box>
  );
};

const HowWorks = () => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [smallScreen, setSmallScreen] = React.useState(screenWidth < 600);

  React.useEffect(() => {
    if (screenWidth < 600) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [screenWidth]);

  return (
    <Box>
      <Typography variant="h1" sx={{ color: '#272620', textAlign: 'center', fontSize: { xs: 30, md: 40 } }}>
        How Does Sabio Work?
      </Typography>
      <Typography
        sx={{
          color: '#272620',
          textAlign: 'center',
          mt: 3,
          mb: { xs: 8, md: 2 },
          fontSize: {
            md: 20,
            xs: 15,
          },
          fontWeight: 350,
        }}
      >
        Feel the Presence of an In-Person Coach, Even Virtually.
      </Typography>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ p: { xs: 0, md: 10 }, pt: { xs: 0, md: 11 } }}>
          <HowWorkBox
            index={1}
            title={'Onboarding Conversation (5 minutes)'}
            label={
              "To get started you'll have a conversation with Sabio, during this he will get to know you, your goal and all the details necessary to curate your plan."
            }
          />
          <HowWorkBox
            index={2}
            title={'Connect your Strava (optional)'}
            label={
              'Connecting Strava allows Sabio to both learn how you usually train, and to understand in real-time how you performed on each workout.'
            }
          />
          <HowWorkBox
            index={3}
            title={'Start your Fitness Journey'}
            label={
              'Once Sabio has created your plan, all you need to do is follow along. Anytime you need to change things, just message Sabio!'
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={'flex'}
          justifyContent={'center'}
          sx={{ p: { xs: 0, md: 10 }, pt: { xs: 5, md: 10 } }}
        >
          <img src={screens} height={smallScreen ? 280 : 400} alt="" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HowWorks;
