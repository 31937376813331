import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';

import theme from './theme.js';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Home from './screens/Home.js';
import PrivacyPolicy from './screens/PrivacyPolicy.js';
import TermsConditions from './screens/TermsConditions.js';

import Blog from './screens/Blog.js';
import BlogPost from './screens/BlogPost.js';

// Define the layout component that will render the nested routes
const Layout = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'terms',
        element: <TermsConditions />,
      },
      { path: 'pricing', element: <Home /> },
      { path: 'reviews', element: <Home /> },
      { path: 'faq', element: <Home /> },
      { path: 'contact', element: <Home /> },
      {
        path: 'blog',
        element: <Blog />,
        children: [
          {
            index: true,
            element: <div />,
          },
          {
            path: ':postId',
            element: <BlogPost />,
          },
        ],
      },
    ],
  },
]);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
