import React from 'react';
import { ReactSVG } from 'react-svg';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import visual from '../assets/contact-left.svg';

import emailjs from '@emailjs/browser';

const Contact = () => {
  const [email, setEmail] = React.useState('');
  const [content, setContent] = React.useState('');

  const [error, setError] = React.useState(false);

  const handleSubmit = async () => {
    if (email === '' || content === '') {
      setError(true);
    } else {
      emailjs.send('service_p7v2s1a', '1', { email, content }, { publicKey: '9i2vPQYdm8sTvCqgh' });
    }
  };

  return (
    <Box sx={{ p: { xs: 0, md: 5 }, pt: { xs: 0, md: 0 } }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h1" sx={{ color: '#272620', textAlign: 'center', fontSize: { xs: 30, md: 40 } }}>
          Contact Us
        </Typography>
        <Typography
          sx={{
            color: '#272620',
            textAlign: 'center',
            mt: 3,
            fontSize: {
              md: 20,
              xs: 15,
            },
            fontWeight: 350,
          }}
        >
          We'd love to hear from you and we're fast to respond.
        </Typography>
        <Grid
          container
          flexWrap={'wrap-reverse'}
          justifyContent={'space-evenly'}
          sx={{ width: { md: '80%', xs: '100%' } }}
        >
          <Grid item xs={12} md={6}>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <ReactSVG src={visual} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: { xs: 5, md: 16 } }}>
            <Box sx={{ maxWidth: 350 }}>
              <TextField
                sx={{ color: '#272620' }}
                InputProps={{
                  style: {
                    borderRadius: '10px',
                  },
                }}
                onFocus={() => setError(false)}
                error={error}
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                sx={{ color: '#272620' }}
                InputProps={{
                  style: {
                    borderRadius: '10px',
                  },
                }}
                onFocus={() => setError(false)}
                error={error}
                fullWidth
                label="Your message"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                multiline
                rows={3}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
                sx={{
                  color: '#fff',
                  width: 100,
                  boxShadow: 'none',
                  '&:hover': { bgcolor: 'primary.main', opacity: 0.8, boxShadow: 'none' },
                }}
              >
                Send
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Contact;
